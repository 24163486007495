import { serviceRequest } from './service-request';

export function getEntryView(formId: string, viewId: string) {
	return serviceRequest(`svc/forms/${formId}/entry-views/${viewId}`, null, { method: 'GET' });
}

export function getEntryViews(formId: string) {
	return serviceRequest(`svc/forms/${formId}/entry-views`, null, { method: 'GET' });
}

export function renameEntryView(formId: string, viewId: string, name: string) {
	return serviceRequest(`svc/forms/${formId}/entry-views/${viewId}/rename`, { name }, { method: 'POST' });
}

export function copyEntryView(formId: string, viewId: string, newName: string) {
	return serviceRequest(`svc/forms/${formId}/entry-views/${viewId}/copy`, { newName }, { method: 'POST' });
}

export function deleteEntryView(formId: string, viewId: string) {
	return serviceRequest(`svc/forms/${formId}/entry-views/${viewId}`, null, { method: 'DELETE' });
}

export function getFieldAssignments(formId: string) {
	return serviceRequest(`svc/forms/${formId}/entry-views/assignments`, null, { method: 'GET' });
}